<!-- 选择产品 -->
<template>
  <!-- 续费产品 -->
  <div class="wrap">
    <div class="navSearch">
      <cardTitleCom cardTitle="应用中的页面" class="navSearchCard">
        <template slot="cardContent">
          <div class="x-w enabledPage">
            <ul v-for="(item, index) in listCopy" :key="index" class="templateEnabled" v-if="item.templateImage">
              <li>
                <div class="image">
                  <el-image style="width: 100%; height: auto; border-radius: 14px" :src="item.templateImage" />
                </div>
                <div class="x-b" style="align-items: flex-end">
                  <div>
                    <div class="typeName">
                      {{ TEMPLATE_TYPE_TEXT[item.templateType] }}
                    </div>
                    <div class="templateName">{{ item.templateName }}</div>
                    <div style="color: #999999; font-size: 14px">
                      {{ `修改时间：${item.updateTime || ""}` }}
                    </div>
                  </div>
                  <div class="editBtn">
                    <el-button size="mini" type="success" @click="handleEvent('update', item)">编辑</el-button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" style="padding-top: 5px" />
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import TablePage from "@/components/tablePage/index.vue";
import Dialog from "@/components/Dialog";
import { TEMPLATE_TYPE_DATA, TEMPLATE_TYPE_TEXT } from "@/utils/constant.js";
import { getTenantProductToRenew } from "@/api/tenant/base/info"; //所有商户
import {
  batchUpdateTemplateStatus,
  templateList,
  deleteTemplate,
  restoreTemplate,
  copyTemplate,
} from "@/api/O2OMall/config/template";

export default {
  name: "TenantList",
  components: { TablePage, Dialog, cardTitleCom },
  data() {
    return {
      TEMPLATE_TYPE_TEXT,
      listCopy: [],
      dialogOptions: {
        title: "",
        width: 550,
        show: false,
        type: "O2OMallTemplate",
        formData: null,
      },
      options: {
        uuid: "c72b89e7-4870-203f-533c-e9559ede42e4",
        tableTitle: true,
        listNo: true,
        mutiSelect: true,
        loading: true,
        check: [],
        getListApi: templateList,
        title: "商城模板",
        rowKey: "templateId",
        search: [
          {
            type: "input",
            tip: "页面名称",
            model: "",
            clearable: true,
            filter: "query",
          },
          {
            type: "local",
            label: "页面类型",
            model: "",
            filter: "templateTypes",
            option: {
              data: [
                { label: "商城首页", value: 1 },
                { label: "分类页", value: 2 },
                { label: "商品详情", value: 3 },
                { label: "会员中心", value: 4 },
                { label: "底部导航", value: 5 },
                { label: "自定义页面", value: 6 },
              ],
              label: "label",
              value: "value",
              multiple: true,
            },
          },
          {
            type: "local",
            label: "状态",
            model: "",
            filter: "isOnLine",
            option: {
              data: [
                { label: "启用", value: true },
                { label: "禁用", value: false }
              ],
              label: "label",
              value: "value",
            },
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            type: "primary",
          },
          {
            click: "copy",
            label: "复制",
            icon: "el-icon-document-copy",
            type: "success",
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            btnType: "dropdown",
            trigger: "click", // 要求
            alertText: "确认要删除选中打印模板吗？",
            other: [
              {
                label: "回收站",
                collectionDialog: {
                  restoreListApi: restoreTemplate,
                  dcollectionBody: {
                    delFlag: "1",
                  },
                },
              },
            ],
          },
          {
            click: "reset",
            label: "刷新",
            right: true,
          },
        ],
        tabsColumns: TEMPLATE_TYPE_DATA.map((x) => ({
          title: x.label,
          type: x.value,
          defaultBody: x.value === 0 ? {} : { templateType: x.value },
          getDataKey: (res) => {
            return {
              list: res.rows,
              total: res.total,
            };
          },
          columns: [
            {
              prop: "templateName",
              label: "页面名称",
              type: "link",
              click: "update",
              minWidth: 150,
            },
            {
              prop: "templateType",
              label: "页面类型",
              minWidth: 100,
              formatter: (v) => TEMPLATE_TYPE_TEXT[v],
            },
            {
              type: "switch",
              prop: "isOnLine",
              label: "启用",
              minWidth: 140,
              active: true,
              inactive: false,
              disabled: (v, row) => v && row.templateType != 6,
              onClick: (t, row) => {
                this.$confirm("确认启用该模板吗？", "提示", {
                  confirmButtonText: "确认",
                  cancelButtonText: "取消",
                })
                  .then(() => {
                    this.handleEvent("updateOnline", row);
                  })
                  .catch(() => {
                    row.isOnLine = false;
                  });
              },
            },
            {
              prop: "isDefault",
              label: "系统默认",
              minWidth: 155,
              formatter: (v) => (v ? "是" : "否"),
            },
            { prop: "describe", label: "备注", minWidth: 180 },
            { prop: "updateBy", label: "修改人", minWidth: 150 },
            { prop: "updateTime", label: "修改时间", minWidth: 150 },
            { prop: "createBy", label: "创建人", minWidth: 150 },
            { prop: "createTime", label: "创建时间", minWidth: 150 },
          ],
        })),
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'getListed':
          this.handleEvent('isOnLineTemplate')
          break;
        case "dialogChange":
          this.$refs.tablePage.getList();
          break;
        case "add":
          {
            const options = {
              show: true,
              title: "新增商城页面",
              click: type,
              formData: {},
            };
            this.dialogOptions = { ...this.dialogOptions, ...options };
          }
          break;
        case "del":
          try {
            await deleteTemplate(this.options.check.map((x) => x.templateId));
            this.$message.success("删除成功！");
            this.$refs.tablePage.getList();
          } catch (error) { }
          break;
        case "updateOnline":
          try {
            await batchUpdateTemplateStatus({
              templateIds: [row.templateId],
              isOnLine: row.isOnLine,
            });
            this.$message.success("设置成功！");
            this.$refs.tablePage.getList();
          } catch (error) { }
          break;
        case "update":
          this.$router.push({
            path: "/O2OMall/config/template/decoratePage/index",
            query: {
              templateId: row.templateId,
            },
          });
          break;
        case "copy":
          let arr = this.options.check.map((v) => v.templateId);
          await copyTemplate(arr);
          this.$message.success("复制成功！");
          this.$refs.tablePage.getList();
          break;
        case 'isOnLineTemplate':
          const res = await templateList({ isOnLine: true, templateTypes: [1, 2, 3, 4] })
          this.listCopy = res.rows?.sort((a, b) => a.templateType - b.templateType);
          break;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.wrap {
  .navSearch {
    background-color: #eaeaea;
    padding: 10px 10px 0 10px;
    .navSearchCard {
      margin-bottom: 0;
      .enabledPage {
        padding: 0 30px;
        -webkit-column-gap: 30px;
        -moz-column-gap: 30px;
        column-gap: 30px;
        .templateEnabled {
          width: 367px;
          height: 558px;
          border: 1px solid #e9edef;
          border-radius: 20px;
          padding: 20px;
          overflow: hidden;
          margin-bottom: 30px;
          list-style: none;
          .image {
            width: 224px;
            height: 399px;
            -webkit-filter: drop-shadow(0px 0px 20px rgba(160, 168, 186, 0.14));
            filter: drop-shadow(0px 0px 20px rgba(160, 168, 186, 0.14));
            border-radius: 14px;
            margin: 0 auto 30px;
            overflow: hidden;
          }
          .typeName {
            width: 68px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            font-size: 12px;
            border-radius: 6px;
            margin-bottom: 10px;
            background-color: #f0faff;
            color: #3e9165;
          }
          .templateName {
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 18px;
            font-weight: bold;
            height: 25px;
            line-height: 25px;
            color: #333;
            margin-bottom: 10px;
          }
          .editBtn {
            ::v-deep .el-button--success {
              background-color: #3e9165;
              border-color: #3e9165;
            }
          }
        }
      }
    }
  }
}
</style>
