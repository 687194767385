<template>
  <div class="fitment">
    <div class="hander x-bc bg-Color">
      <div class="pageList x-f">
        <div class="marR20 item x-c" :class="{ item2: index == pageIndex }" v-for="(item, index) in pagelist"
          :key="index" @click="pageClick(item, index)">
          {{ item.text }}
        </div>
      </div>
      <div class="btnList x-f">
        <el-button type="info" size="mini" icon="el-icon-setting" @click="pageSetup" v-if="templateType == 1">页面设置
        </el-button>
        <el-button type="info" size="mini" border="#a8a7a7" class="whiteColor" @click="getBack" plain>返回
        </el-button>
        <el-button type="primary" size="mini" class="whiteColor" @click="submit">保存
        </el-button>
        <!-- <el-button
          type="primary"
          size="mini"
          class="whiteColor"
          @click="getDefaultTemplate"
          >设为默认模版
        </el-button> -->
      </div>
    </div>
    <div class="x-start main">
      <left-template class="left-tempTag bg-Color" @addTemplate="addTemplate" />
      <conter-preview ref="preview" :headline="headline" :templateType='templateType' @editTemp="editTemp" class="conter x-f1"></conter-preview>
      <right-statue :rightData="rightData" class="right-statue bg-Color" @setTemp="setTemp"
        @setTemphead="setTemphead"></right-statue>
    </div>
  </div>
</template>

<script>
import CButton from "@/views/components/c-button/index.vue";
import LeftTemplate from "@/views/O2OMall/config/template/decoratePage/components/left-template.vue";
import RightStatue from "@/views/O2OMall/config/template/decoratePage/components/right-statue.vue";
import ConterPreview from "@/views/O2OMall/config/template/decoratePage/components/conterPreview.vue";
import {
  configTemplateDetail,
  templateList,
  templateSave,
} from "@/api/O2OMall/config/template"; //素材库
import { mapState } from "vuex";

export default {
  name: "index",
  components: {
    CButton,
    LeftTemplate,
    RightStatue,
    ConterPreview,
  },
  // 渲染该组件前调用这个路由钩子
  beforeRouteEnter(to, from, next) {
    //因为当守卫执行前,组件实例还没创建
    //一定要调用next()方法才能跳转到后续页面,否则不会解析导致无法进入页面
    //通过'vm'才能访问组件this实例
    next((vm) => {
      //根据商品id 获取商品详情
      if (to.query.type === "add") {
        //新增
        vm.type = "add";
        //初始化数据
        vm.$store.state.template.tempData = [];
        vm.$store.state.template.fixationTempData = [];
        if (to.query.categoryId) vm.form.categoryId = to.query.categoryId;
      } else {
        //页面加载动画
        vm.boxLoading = true;
        //修改
        (vm.type = "update"),
          //初始化数据
          (vm.$store.state.template.tempData = []),
          (vm.$store.state.template.fixationTempData = []);
      }
      //存储form原始数据 以便后续判断是否修改了页面内容
      vm.prevTempData = JSON.stringify(vm.tempData);
      vm.prevFixationTempData = JSON.stringify(vm.fixationTempData);
    });
  },
  // 导航离开该组件的对应路由时调用
  // 可以访问组件实例 `this`
  beforeRouteLeave(to, from, next) {
    if (
      JSON.stringify(this.tempData) === this.prevTempData ||
      JSON.stringify(this.fixationTempData) == this.prevFixationTempData
    ) {
      next();
    } else {
      //form数据修改过,提示用户是否保存
      this.$confirm("页面模板已经修改, 请问是否保存本次修改?", "提示", {
        confirmButtonText: "保存",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await this.submit();
          next();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消保存",
          });
          next();
        });
    }
  },
  data() {
    return {
      templateType: 1,
      pagelist: [],
      pageIndex: -1,
      head: {
        name: "home",
        text: this.headline,
      },
      // tempData: [], // 添加的组件以及组件参数
      prevTempData: [],
      prevFixationTempData: [],
      headline: "",
      // rightData: {},
      labelList: [], // 操作删除列表
      templateId: "", // 当前模板ID
      templateName: "",
    };
  },
  computed: {
    ...mapState({
      tempData: (state) => state.template.tempData,
      fixationTempData: (state) => state.template.fixationTempData,
      pageName: (state) => state.template.pageName,
      rightData: (state) => state.template.rightData,
      // 使用数据
      basicsIconList: (state) => state.template.basicsIconList,
      marketingIconList: (state) => state.template.marketingIconList,
      goodsDetailList: (state) => state.template.goodsDetailList,
      categoryPageList: (state) => state.template.categoryPageList,
      personalCenterList: (state) => state.template.personalCenterList,
      // 基础数据
      basicsIcon: (state) => state.template.basicsIcon,
      marketingIcon: (state) => state.template.marketingIcon,
      goodsDetail: (state) => state.template.goodsDetail,
      categoryPage: (state) => state.template.categoryPage,
      personalCenter: (state) => state.template.personalCenter,
    }),
  },
  mounted() {
    this.templateId = this.$route.query.templateId;
    this.getTemplateList();
  },
  methods: {
   ...Vuex.mapActions({
      setTemplateRightData: 'setTemplateRightData',
      changeIndex: 'changeIndex'
    }),
    pageSetup(){
      this.$store.state.template.selectIndex = null
      let data = this.$store.state?.template?.tempData?.find(v=> v?.name === 'pageData')
      if (!data) {
        data = {
          name: 'pageData',
          style: {
            bgColor:'#f5f5f5',
            title:undefined,
            topBGTypeObj:{
              type:1,
              bgColor:'',
              url:''
            },
            capsuleType:1
          },
        }
      }
      this.$store.state.template.tempData.push(data);
      this.setTemplateRightData(data)
    },
    getTemplateList() {
      configTemplateDetail(this.templateId).then((res) => {
        console.log("组件-----：", res.rows);
        if (res.data) {
          const item = res.data;
          console.log("请求组件：" + this.pageName, JSON.parse(item.templateContent));
          this.pagelist = [
            { text: "商城首页", name: "indexPage", type: 1 },
            { text: "分类页", name: "categoryPage", type: 2 },
            { text: "商品详情", name: "goodsDetail", type: 3 },
            { text: "个人中心", name: "personalCenter", type: 4 },
            { text: "底部导航栏", name: "navigation", type: 5 },
            { text: "自定义页面", name: "pagesCustom", type: 6 },
          ].filter((x) => x.type === item.templateType);
          this.pageClick(this.pagelist[0], 0);
          this.templateName = item.templateName;
          if (item.templateContent) {
            let templateContent = JSON.parse(item.templateContent);
            this.$store.state.template.tempData = templateContent.tempData;
            this.$store.state.template.fixationTempData =
              templateContent.fixationTempData;
            if (JSON.stringify(templateContent.tempData[0]) != "{}") {
              this.$store.state.template.rightData = templateContent.tempData[0];
            } else if (
              JSON.stringify(templateContent.tempData[0]) == "{}" &&
              JSON.stringify(templateContent.fixationTempData[0]) != "{}"
            ) {
              this.$store.state.template.rightData = templateContent.fixationTempData[0];
            }
            console.log("模板列表：", this.$store.state.template.rightData);
            this.statisticalMagnitude([...this.tempData, ...this.fixationTempData]);
          }
        }
      });
    },
    // 初始化左侧组件数据
    init() {
      this.$store.state.template.basicsIconList = JSON.parse(
        JSON.stringify(this.basicsIcon)
      );
      this.$store.state.template.marketingIconList = JSON.parse(
        JSON.stringify(this.marketingIcon)
      );
      this.$store.state.template.goodsDetailList = JSON.parse(
        JSON.stringify(this.goodsDetail)
      );
      this.$store.state.template.categoryPageList = JSON.parse(
        JSON.stringify(this.categoryPage)
      );
      this.$store.state.template.personalCenterList = JSON.parse(
        JSON.stringify(this.personalCenter)
      );
      console.log("初始化数据==1==", this.marketingIcon);
      console.log("初始化数据==2==", this.$store.state.template.marketingIconList);
    },
    pageClick(item, index) {
      // console.log('点击：', index
      if (index !== this.pageIndex) {
        this.pageIndex = index;
        this.$store.state.template.pageName = item.name;
        this.$store.state.template.rightData = {};
        this.$store.state.template.tempData = [];
        this.headline = item.text;
        this.templateType = item.type;
        console.log("点击：", this.pageName);
      }
    },
    addTemplate(obj) {
      this.labelList.push(obj.labelList);
      this.rightData = obj.obj;
      this.$store.state.template.tempData.push(obj.obj); // 数据放到Vuex，方便全局使用
      this.$store.state.template.tempData.push(obj.obj);
      // this.tempData.push(obj.obj)
      console.log("已选择的组件", obj.obj);
      console.log("已选择的组件", this.rightData);
    },
    // 右边状态信息栏传回信息
    setTemp(data) {
      this.$store.state.template.tempData[this.$store.state.template.selectIndex] = data;
      this.$store.state.template.rightData = data;
      console.log('setTemp提交：', data)
      this.$forceUpdate()
      // this.$set(this.$store.state.template, 'rightData', data)
    },
    // 已选中的组件被点击后进入编辑
    editTemp(data) {
      console.log("点击了，快来编辑", data);
      // this.rightData = data
    },
    // 修改页面标题
    setTemphead(data) {
      this.headline = data.headline;
    },
    // 返回上一页
    getBack() {
      this.$router.go(-1);
    },
    // 默认模版
    getDefaultTemplate() {
      let tempDataList = {
        tempData: this.tempData,
        fixationTempData: this.fixationTempData,
      };
      if (JSON.stringify(tempDataList) != "{}") {
        templateSave({
          isDefault: true,
          templateId: this.templateId,
          templateContent: JSON.stringify(tempDataList),
        }).then((res) => {
          this.$message.success("保存默认模版成功");
        });
      }
    },
   async submit() {
      // const goodsList = this.tempData.filter(e => e.name == 'goods')
      // if (goodsList.length) {
      //   const list = goodsList.filter(e => !e.goodList.length)
      //   if (list.length) {
      //     return this.$message.warning('商品组件不能为空')
      //   }
      // }
      const loading = this.$loading({
        lock: true,
        text: '保存中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      try {
        const goods = this.tempData.findIndex(e => e?.name == 'goods')
        if (goods != '-1') {
          const goodsList = this.tempData.filter(e => e?.name == 'goods')
          const list = goodsList.filter(e => !e.goodList.length)
          if (list.length) {
            return this.$message.warning('商品组件不能为空')
          }
        }
        let tempDataList = {
          tempData: this.tempData,
          fixationTempData: this.fixationTempData,
        };
        console.log('保存模板2：', tempDataList)
        if (JSON.stringify(tempDataList) != "{}") {
          let templateImage = await this.$refs.preview.generateImage()
          templateSave({
            templateId: this.templateId,
            templateContent: JSON.stringify(tempDataList),
            templateName: this.templateName,
            templateImage
          }).then((res) => {
            this.$message.success("保存成功");
          });
        }
        loading.close();
      } catch (error) {
        loading.close();
        console.log("保存失败", error);
      }
    },
    // 统计组件数量，用于控制使用数量
    statisticalMagnitude(data) {
      let dataL = data.length;
      if (dataL <= 0) return;
      this.init();
      for (let t = 0; t < dataL; t++) {
        if (!data?.[t]?.name) continue;
        // 基础组件
        let basicsL = this.basicsIconList.length;
        for (let i = 0; i < basicsL; i++) {
          if (data[t].name == this.basicsIconList[i].name) {
            let num = this.basicsIconList[i].num + 1;
            this.$store.state.template.basicsIconList[i].num = num;
          }
        }
        // 营销组件
        let marketingL = this.marketingIconList.length;
        for (let i = 0; i < marketingL; i++) {
          if (data[t].name == this.marketingIconList[i].name) {
            let num = this.marketingIconList[i].num + 1;
            this.$set(this.marketingIconList[i], "num", num);
          }
        }
        // 商品详情组件
        let goodsDetailL = this.goodsDetailList.length;
        for (let i = 0; i < goodsDetailL; i++) {
          if (data[t].name == this.goodsDetailList[i].name) {
            let num = this.goodsDetailList[i].num + 1;
            this.$set(this.goodsDetailList[i], "num", num);
          }
        }
        // 分类组件
        let categoryL = this.categoryPageList.length;
        for (let i = 0; i < categoryL; i++) {
          if (data[t].name == this.categoryPageList[i].name) {
            let num = this.categoryPageList[i].num + 1;
            this.$set(this.categoryPageList[i], "num", num);
          }
        }
        // 个人中心组件
        let personalL = this.personalCenterList.length;
        for (let i = 0; i < personalL; i++) {
          if (data[t].name == this.personalCenterList[i].name) {
            let num = this.personalCenterList[i].num + 1;
            this.$set(this.personalCenterList[i], "num", num);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fitment {
  background: #f6f7f9;
  padding-bottom: 10px;
  font-size: 12px;
  height: 100%;

  .hander {
    width: 100%;
    height: 70px;
    padding: 5px;

    .pageList {
      cursor: pointer;

      .item {
        width: 100px;
        padding: 15px 8px;
      }

      .item2 {
        background: #2b8ced;
        color: #ffffff;
      }
    }
  }

  .main {
    height: calc(100% - 70px);

    .left-tempTag,
    .right-statue {
      width: 300px;
      border-top: 1px solid #eaeaea;
    }

    .right-statue {
      width: 500px;
    }

    .conter {
      width: calc(100% - 689px);
      //height: calc(100vh - 100px);
    }
  }

  ::v-deep .el-dialog__body {
    padding: 0;
  }
}
</style>
